<template>
  <div
    class="col-xl-3 col-lg-12 col-md-4 chat-list-wrapper px-0 conversationList"
  >
    <div class="conversationList__new__conversation p-2">
      <div>
        <div v-if="infoCurrentProjectInfo">
          <span class="text-muted">{{
            infoCurrentProjectInfo.description
          }}</span>

          <b-button
            variant="btn"
            class="ml-2"
            @click="handleSoundNotify"
            v-b-tooltip.hover
            :title="
              activedSound
                ? 'Notificações sonoras ATIVADA'
                : 'Notificações sonoras DESATIVADA'
            "
          >
            <i class="mdi mdi-bell" v-if="activedSound"></i>
            <i class="mdi mdi-bell-off" v-else></i>
          </b-button>
        </div>
      </div>

      <div>
        <button
          class="btn"
          :disabled="!conversationsClient"
          v-b-modal.modal-whatsapp-new-conversation
        >
          <svg viewBox="0 0 24 24" width="16" height="16" class="">
            <path
              fill="currentColor"
              d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
            ></path>
          </svg>
        </button>

        <b-dropdown
          dropright
          dropup
          size="sm"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template #button-content>
            <i data-v-14bb84ae="" class="mdi mdi-dots-vertical"></i>
          </template>

          <b-dropdown-item v-b-modal.modal-selected-number>
            <i class="mdi mdi-whatsapp"></i> Selecionar</b-dropdown-item
          >

          <b-dropdown-item @click="logout">
            <i class="mdi mdi-logout"></i> Desconectar</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <div class="text-center mt-5" v-if="conversations.length == 0">


      <div>
        <h4
          class="m-0 text-center"
          :class="statusString == 'Conectado' ? 'text-success' : 'text-muted'"
        >
          {{ statusString }}
        </h4>
      </div>




      <span class="text-muted">Não há conversas a serem exibidas</span>


      <!-- <b-spinner small label="Small Spinner" type="grow"></b-spinner> -->
    </div>

    <div class="chat-list-item-wrapper pt-0">
      <div
        class="list-item d-flex"
        :class="item.sid === selectedConversationSid ? 'chat__selected' : ''"
        v-for="item in conversations"
        :key="item.sid"
        @click="setConversation(item)"
      >
        <div class="w-100">
          <p class="chat-text pl-0">
            {{ item.friendlyName || item.sid }}
          </p>

          <p class="user-name pl-0">
            <LastMessage :lastMessage="item" />
          </p>
        </div>
      </div>
    </div>

    <!-- <WhatsAppNewConversationVue/> -->

    <!-- <p v-for="(item, index) in testeAa" :key="index" >lalala</p> -->
  </div>
</template>

<script>
import LastMessage from "./LastMessage.vue";
import conversationState from "@/store/module-observable/conversation/index";

import {
  setActiveSound,
  activedSound,
  infoCurrentProjectInfo,
  logoutConversation,
  setInfoCurrentProjectInfo,
  statusString,
} from "@/store/module-observable/conversation";

export default {
  components: { LastMessage },
  props: [
    "conversations",
    "selectedConversationSid",
    "onConversationClick",
    "conversationsClient",
  ],
  data() {
    return {
      itemSelected: undefined,
    };
  },

  mounted() {},

  updated() {
    // console.log("update here");
    //MUDAR ISSO PRA WATCH
    // if (this.itemSelected) {
    //   if (this.itemSelected.sid === this.selectedConversationSid) {
    //     this.setCurrentConversation.isCurrentConversation = this.itemSelected
    //     this.setReadConversation(this.itemSelected);
    //   }
    // }
  },
  methods: {
    setActiveSound,
    logoutConversation,
    setInfoCurrentProjectInfo,
    setConversation(item) {
      this.onConversationClick(item);
      this.setReadConversation(item);

      this.itemSelected = item;
      // console.log(item);
      // item.updateLastReadMessageIndex(10)
    },
    setReadConversation(conversation) {
      // console.log();
      const index = conversation.lastMessage.index;
      conversation.updateLastReadMessageIndex(index);
    },

    handleSoundNotify() {
      this.setActiveSound(!this.activedSound);
      localStorage.setItem("activedSound", this.activedSound);
    },

    async logout() {
      this.logoutConversation();      
      // localStorage.removeItem("usernameChat");
      // this.setInfoCurrentProjectInfo(undefined)

    },


  },
  computed: {
    setCurrentConversation() {
      return conversationState;
    },
    activedSound,
    infoCurrentProjectInfo,
    statusString
  },
  watch: {
    conversations() {
      // console.log(this.conversations);

      this.$nextTick(function () {
        // console.log("update here conversations depois de atualizado");

        if (this.itemSelected) {
          if (this.itemSelected.sid === this.selectedConversationSid) {
            this.setCurrentConversation.isCurrentConversation =
              this.itemSelected;

            this.setReadConversation(this.itemSelected);
          }
        }
      });
    },
    itemSelected() {
      // console.log("itemSelected ", this.itemSelected);
    },
  },
};
</script>

<style scoped lang="scss">
.conversationList {
  overflow-y: hidden;
    max-width: 30%;
    flex: 0 0 30%;


  .chat-list-item-wrapper {
    overflow-y: auto;
    height: 100%;

    /* SCROLLBAR */
    /* Let's get this party started */

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .chat__selected {
    background: rgba(0, 144, 231, 0.1);
  }
  .conversationList__new__conversation {

    border-bottom: 1px solid #2c2e33;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 48px;

    
  }
}
</style>


<style>
</style>