<template>
  <b-modal
    v-model="modalShow"
    id="modal-selected-number"
    title="Selecione uma conta WhatsApp"
    hide-footer
  >
    <div class="d-flex justify-content-between align-items-center flex-column">
      

      <div v-if="conversationsClient">
        <h4
          class="m-0 text-center"
          :class="statusString == 'Conectado' ? 'text-success' : 'text-muted'"
        >
          {{ statusString }}
        </h4>
      </div>

      <div class="d-flex">
        <b-form-select
          v-model="selected"
          :options="options"
          :disabled="!!conversationsClient"
          size="sm"
        ></b-form-select>

        <button
          class="btn btn-primary ml-2 d-flex align-items-center"
          v-if="!conversationsClient"
          @click="loginConversation"
          :disabled="!selected || loading"
        >
          <template v-if="loading">
            <b-spinner small type="grow"></b-spinner>
            Entrando...
          </template>
          <template v-else> Entrar </template>
        </button>
        <button class="btn btn-danger ml-2" v-else @click="logout">Sair</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  statusString,
  conversationsClient,
  setUserNameChat,
  logoutConversation,
  getTokenConversation,
  setInfoCurrentProjectInfo,
} from "@/store/module-observable/conversation";
import TwilioService from "../../../services/twilio.service";

export default {
  data() {
    return {
      modalShow: false,
      options: [
        // { value: null, text: 'Selecione um número:' },
        // { value: { telefone: '551132303906', description: "Projeto PIATTINO" }, text: 'Piattino +551132303906' },
        // { value: { telefone: '551150392240', description: "Projeto NUTANIX" }, text: 'Nutanix +551150392240' },
      ],
      selected: null,
      loading: false,
      
    };
  },

  mounted() {
    this.selected = JSON.parse(localStorage.getItem("selected-number"));

    this.getNumbersTwilio();
  },
  methods: {
    setUserNameChat,
    logoutConversation,
    getTokenConversation,
    setInfoCurrentProjectInfo,
    getNumbersTwilio() {
      TwilioService.getNumbersTwilio()
        .then((res) => {
          this.options = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    loginConversation() {
      localStorage.setItem("selected-number", JSON.stringify(this.selected));
      const usernameChat = `whatsapp:+${this.selected.telefone}`;

      localStorage.setItem("usernameChat", usernameChat);

      this.setUserNameChat(usernameChat); // global

      //global state

      this.getTokenConversation();


      this.setInfoCurrentProjectInfo(this.selected)
    },
    async logout() {
      this.logoutConversation();      
      this.setInfoCurrentProjectInfo(undefined)

    },
  },

  computed: {
    statusString,
    conversationsClient,
  },


  watch:{
    
  }
};
</script>

<style>
</style>