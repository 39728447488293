<template>
  <div class="position-relative d-flex justify-content-between">
    <!-- <img 
    src="@/assets/images/chat/health-health.png"
    
    alt="aa"> -->

    <span class="last__message">
      <span title="mensagem não enviada">
        <FailIcon
          v-if="statusMessage === 'undelivered' || statusMessage === 'failed'"
        />
      </span>

      <QueueddIcon v-if="statusMessage === 'queued'" />

      <SendIcon v-if="statusMessage === 'sent'" />
      <DeliveredIcon v-if="statusMessage === 'delivered'" />
      <DeliveredIcon style="color: #53bdeb" v-if="statusMessage === 'read'" />
      <span
        class="ml-1"
        :class="lastMessageText === 'Mensagem de mídia' ? 'text-primary' : ''"
        >{{ lastMessageText }}
        <i
          class="mdi mdi-paperclip icon-sm"
          v-if="lastMessageText === 'Mensagem de mídia'"
        ></i>
      </span>
    </span>

    <div class="d-flex justify-content-end" style="margin-top: -15px">
      <div class="d-flex flex-column align-items-center justify-content-center">
        <!-- <div class="badge badge-primary badge-pill" v-if="(countMessageUnread > 0) &&  lastMessage.sid != isCurrentConversation.sid">{{countMessageUnread}}</div> -->
        
        <div
          class="badge badge-primary badge-pill"
          v-if="lastMessage.sid != isCurrentConversation.sid"
        >

        <!-- <div
          class="badge badge-primary badge-pill"
          v-if="lastMessage.sid != activeConversation.sid"
        > -->
          <span v-if="countMessageUnread > 0">{{ countMessageUnread }}</span>
          <span v-if="newConversation">Novo</span>
        </div>
        <div class="time__last__message mt-1">{{ timeLastMessageText }}</div>
      </div>
    </div>
<!-- {{countMessageUnread}}

{{lastMessage.sid}}
-->

<!-- <span v-if="activeConversation">{{activeConversation.sid}}</span>  -->

  </div>
</template>

<script>
import conversationState from "../../../store/module-observable/conversation/index";

// import Logo from "@/assets/images/chat/send.svg"
import SendIcon from "./SendIcon.vue";
import DeliveredIcon from "./DeliveredIcon.vue";
import FailIcon from "./FailIcon.vue";
import QueueddIcon from "./QueueddIcon.vue";

import { listAllMessages, activeConversation, resetIscurrentConversation } from "../../../store/module-observable/conversation";

export default {
  props: ["lastMessage"],
  components: { SendIcon, DeliveredIcon, FailIcon, QueueddIcon },
  data() {
    return {
      lastMessageText: "",
      timeLastMessageText: "",
      countMessageUnread: 0,
      statusMessage: undefined,
      newConversation: false,
    };
  },

  mounted() {
    // console.log(this.lastMessage);
    this.loadLastMessage();
    this.unreadMessageCount();
    this.listeners();

    // console.log(this.updateMessage);
  },
  methods: {
    resetIscurrentConversation,
    loadLastMessage() {
      this.lastMessage.getMessages().then((data) => {
        const lastMessage = data.items[data.items.length - 1];
        // console.log(lastMessage);

        if (lastMessage?.type === "media") {
          this.lastMessageText = "Mensagem de mídia";
        } else {
          this.lastMessageText = data.items[data.items.length - 1]?.body;
        }

        this.timeLastMessageText = this.getLastMessageTime(data) || "";

        // const lasMessage = data.items[data.items.length - 1]

        this.getStatusMessage(lastMessage);

        // isMediaMessage(){}
      });
    },

    getLastMessageTime(lastmessage) {
      //   console.log(lastmessage);
      const lastMessageDate =
        lastmessage.items[lastmessage.items.length - 1]?.dateCreated;
      //   console.log(lastMessageDate);
      const today = new Date();
      const diffInDates = Math.floor(
        today?.getTime() - lastMessageDate?.getTime()
      );
      const dayLength = 1000 * 60 * 60 * 24;
      const weekLength = dayLength * 7;
      const yearLength = weekLength * 52;
      const diffInDays = Math.floor(diffInDates / dayLength);
      const diffInWeeks = Math.floor(diffInDates / weekLength);
      const diffInYears = Math.floor(diffInDates / yearLength);
      if (diffInDays < 0) {
        return "";
      }
      if (diffInDays === 0) {
        const minutesLessThanTen = lastMessageDate.getMinutes() < 10 ? "0" : "";
        return (
          lastMessageDate.getHours().toString() +
          ":" +
          minutesLessThanTen +
          lastMessageDate.getMinutes().toString()
        );
      }
      if (diffInDays === 1) {
        return "1 dia atrás";
      }
      if (diffInDays < 7) {
        return diffInDays + " dia atrás";
      }
      if (diffInDays < 14) {
        return "1 semana atrás";
      }
      if (diffInWeeks < 52) {
        return diffInWeeks + " semana atrás";
      }
      if (diffInYears < 2) {
        return "1 ano atrás";
      }
      return diffInYears + " ano atrás";
    },

    unreadMessageCount() {
      // console.log(this.lastMessage.channelState.lastMessage);
      // console.log(this.lastMessage.channelState.lastReadMessageIndex);
      if (
        !this.lastMessage.channelState.lastMessage ||
        !this.lastMessage.channelState.lastReadMessageIndex
      ) {
        return (this.newConversation = true);
      }
      this.newConversation = false;

      this.countMessageUnread =
        this.lastMessage.channelState.lastMessage?.index -
        this.lastMessage.channelState.lastReadMessageIndex;

    },

    async getStatusMessage(lasMessage) {
      // console.log(this.isMyMessage(lasMessage));
      if (!this.isMyMessage(lasMessage))
        return (this.statusMessage = undefined);

      const data = await lasMessage.getDetailedDeliveryReceipts();
      if (data.length > 0) {
        this.statusMessage = data[0].status;
        this.updateMessage.updateMessages = this.statusMessage;
      }

      // console.log(this.statusMessage);
    },
    listeners() {
      this.lastMessage.on("messageUpdated", (state) => {
        // console.log("*** messageUpdated ", state);
        // console.log("mudou aqui ", state);
        this.getStatusMessage(state.message);
        // this.updateMessage.updateMessages = state.message
      });
    },
    isMyMessage(lasMessage) {
      if (
        lasMessage === undefined ||
        lasMessage === null ||
        lasMessage.length === 0
      ) {
        return false;
      }

      if (lasMessage.author === localStorage.getItem("usernameChat"))
        return true;

      return false;
    },
    // isMediaMessage
  },
  computed: {
    isCurrentConversation() {
      return conversationState.isCurrentConversation;
    },
    updateMessage() {
      return conversationState;
    },
    listAllMessages,
    activeConversation
  },

  watch: {
    isCurrentConversation() {
      // console.log(this.isCurrentConversation);
    },

    listAllMessages() {

      this.$nextTick(function () {
        this.loadLastMessage();
        this.unreadMessageCount();
      });
    },
  },

  beforeDestroy() {
    this.lasMessage?.removeAllListeners();
    // this.isCurrentConversation.sid = ""
    this.resetIscurrentConversation();
  },
};
</script>

<style scoped lang="scss">
.last__message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>