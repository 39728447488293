<template>

  <section class="chat">
    <div class="row h-100">
      <div class="col-md-12 h-100">
        <div class="card chat-app-wrapper">
          <div class="row mx-0 h-100 flex-nowrap">
            <!-- style="height: calc(100% - 63px) !important" -->

            <!-- <h4 class="m-2">{{statusString}}</h4> -->

            <ConversationsList
              :conversations="conversations"
              :selectedConversationSid="selectedConversationSid"
              :onConversationClick="onConversationClick"
              :conversationsClient="conversationsClient"
            />

            <ConversationView
              :conversationProxy="selectedConversation"
              :resetSelectedConversation="resetSelectedConversation"
            />
            <!-- :usernameChat="usernameChat" -->

            <div
              v-if="!selectedConversation"
              class="w-100 d-flex align-items-center justify-content-center"
            >
              <h5 class="text-muted" v-if="conversations.length > 0">
                Selecione uma conversa ao lado para começar
              </h5>
              <h5 class="text-muted" v-if="conversations.length == 0">
                Não há mensagens a serem exibidas
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <WhatsAppNewConversation />

    <ModalSelectWhatsApp />
  </section>

  <!-- <h1>before</h1> -->
</template>

<script>
// import axios from "axios";

// import { Client } from "@twilio/conversations";
import ConversationsList from "./components/ConversationsList.vue";
import ConversationView from "./components/ConversationView.vue";
// import { mapMutations } from 'vuex';
import conversationState from "@/store/module-observable/conversation/index";
// import TwilioService from "../../services/twilio.service";
import WhatsAppNewConversation from "./components/WhatsAppNewConversation.vue";

import {
  statusString,
  conversations,
  conversationsClient,
  getTokenConversation,
  logoutConversation,
  setActiveSound,
  setUserNameChat,
  userNameChat,
  setInfoCurrentProjectInfo,
} from "@/store/module-observable/conversation";
import ModalSelectWhatsApp from "./components/ModalSelectWhatsApp.vue";

export default {
  components: {
    ConversationsList,
    ConversationView,
    WhatsAppNewConversation,
    ModalSelectWhatsApp,
  },

  data() {
    return {
      selectedConversationSid: null,
      selectedConversation: undefined,
    };
  },

  mounted() {
    const selected_number = JSON.parse(localStorage.getItem("selected-number"));
    this.setUserNameChat(localStorage.getItem("usernameChat")); // global
    this.setInfoCurrentProjectInfo(selected_number);
    // this.currentProject.currentProjectInfo.telefone = this.selected;

    document.querySelector("html").style = "overflow: hidden;";
  },

  methods: {
    getTokenConversation,
    logoutConversation,
    setActiveSound,
    setUserNameChat,
    setInfoCurrentProjectInfo,

    onConversationClick(item) {
      this.selectedConversationSid = item.sid;
    },
    resetSelectedConversation() {
      this.selectedConversation = undefined;
      this.selectedConversationSid = null;
    },
  },

  updated() {},

  beforeDestroy() {
    this.resetSelectedConversation();
    document.querySelector("html").style = "overflow: auto;";
  },
  watch: {
    selectedConversationSid() {
      const selectedConversationSelected = this.conversations.find(
        (it) => it.sid === this.selectedConversationSid
      );

      this.selectedConversation = selectedConversationSelected;
    },

    userNameChat() {
      if (!this.userNameChat) {
        //if nao houver user, limpa items selecionados
        this.resetSelectedConversation();
      }
    },
    conversationsClient() {
      if (!this.conversationsClient) {
        //if nao houver user, limpa items selecionados
        this.resetSelectedConversation();
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      return this.$store.getters["auth/hasRole"]("admin");
    },
    listMessages() {
      return conversationState;
    },

    statusString,
    conversations,
    conversationsClient,
    userNameChat,
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  height: 100%;
  height: calc(100vh - 120px);
}

.chat-app-wrapper {
  height: 100%;
}
</style>



<style scoped lang="scss">
</style>